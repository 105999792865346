import React from "react";
import App from "./App";
import ApolloClient from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createHttpLink } from "apollo-link-http";
import { ApolloProvider } from "@apollo/react-hooks";

const httpLink = createHttpLink({
  // uri: "http://localhost:5000/graphql",
  uri: "https://nayayougbodh.com/graphql",
});

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
  // defaultOptions: {
  //   watchQuery: {
  //     fetchPolicy: 'no-cache'
  //   }
  // }
});

export default (
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
);
